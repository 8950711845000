import React, { useState, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom/client';
import {Link, useParams, useNavigate} from "react-router-dom";
import {queryPHP, postPHP, MyInput, MySelect, utils} from '../utils/utils'
import {getStoredPlan, storePlan, getStoredPlanOriginal, storePlanOriginal, getStoredSettings, storeSettings} from '../utils/redux'
import { useSpring, animated } from '@react-spring/web'
import {useSelector, useDispatch} from 'react-redux'

export default function Bilderplan(props) {
	const dispatch = useDispatch()
	const storedPlan = useSelector(getStoredPlan)
	const settings = useSelector(getStoredSettings)
	const [navigation, setNavigation] = useState(0)
	const [selectedBild, setSelectedBild] = useState(0)
	const [pos, setStatePos] = useState(settings.pos);
	const [part, setStatePart] = useState(settings.part);
	const [planOriginal, setStatePlanOriginal] = useState(useSelector(getStoredPlanOriginal))
	const [plan, setStatePlan] = useState(storedPlan)
	const [editMode, setEditMode] = useState(false)
	const [selectedPoints, setSelectedPoints] = useState([])
	const [dlg, setDlg] = useState(false)
	const [planIds, setPlanIds] = useState([])

	const setPos = idx => {
		dispatch(storeSettings({...settings, pos:idx}))
		setStatePos(idx)
	}
	const setPart = idx => {
		dispatch(storeSettings({...settings, part:idx}))
		setStatePart(idx)
	}

	const setPlan = p => {
		p.changed = !compare(p.bilder, planOriginal.bilder, p.pairs)
		console.log(p)
		dispatch(storePlan(p))
		setStatePlan(p)
	}
	const showDlg = element => {
		ReactDOM.createRoot(document.getElementById('dialogContainer')).render(element)
		setDlg(true)
	}

	const closeDlg = () => {
		setDlg(false)
	}

	const updatePlan = data => {
		data.changed = false
		data.loaded = new Date()
		dispatch(storePlan(data))
		dispatch(storePlanOriginal(data))
		setStatePlan(data)
		setStatePlanOriginal(data)
	}

	const showErr = err => {
		showDlg((<div>
				{err}
				<br/>
				<button onClick={closeDlg}>ok</button>
			</div>))
	}

	const load = () => {
		queryPHP("formationPlan", {}, updatePlan, showErr);
	}

	useEffect(() => {
		if(storedPlan.changed) return
		if(settings.allowCookies) load()
		else showDlg((<div>
				Diese Seite verwendet Cookies zum Speichern des Bilderplans (für die offline-nutzung) und der Auswahl der Position in der Übersicht
				<br/>
				<button onClick={() => {dispatch(storeSettings({...settings, allowCookies:true}));load();closeDlg()}}>akzeptieren</button>
			</div>))

	}, [])

	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	const landscape = windowSize.current[0] > windowSize.current[1]
	const unit = landscape ? windowSize.current[1]/100 : windowSize.current[0]/100
	const rWidth = windowSize.current[0] - 80*unit < 290 ? '100vw' : `calc(100vw - ${80*unit+16}px)`

	let bilder = plan.bilder
	let bild = bilder[selectedBild]
	let bild2 = selectedBild == 0 ? bild : bilder[selectedBild-1]
	const selectBild = idx => {
		setSelectedBild(idx);
		setNavigation(1);
		if(!bilder[idx].followers) setSelectedPoints(selectedPoints.filter(x => x<8))
	}

	const getContent = () => {
		switch(navigation) {
			case 0:
				let args = {pos, setPos, part, setPart, plan, selected:selectedBild}
				return <PlanTable {...args} select={selectBild} />
			case 1:
				let args2 = {pos, part, bild, bild2, unit, selectedBild, selectBild, plan, setPlan, updatePlan, editMode, setEditMode, selectedPoints, setSelectedPoints, showDlg, closeDlg, origin:planOriginal.id}
				return editMode ? <EditTab {...args2} rWidth={rWidth}/> : <BildTab {...args2}/>
			case 2: return <InfoTab
				reset={() => setPlan(utils.clone(planOriginal))}
				showDlg={showDlg}
				closeDlg={closeDlg}
				planIds={planIds}
			/>
			default: return null
		}
	}


	return (<div id="bilderplan" className={unit < 7 ? 'mobile' : ''}>
		<div style={{paddingBottom:'100px', overflowY:'scroll', height:'100vh'}}>
			<div><b>Stand: {new Date(planOriginal.saved).toLocaleDateString()} {plan.changed && '(bearbeitet)'}</b></div>
			{
				getContent()
			}
		</div>
		<div id="navigation">
			{
				['▤','▦','ⓘ'].map((text,i) => (
					<div key={i} className={navigation==i ? 'selected' : ''} onClick={() => setNavigation(i)}>{text}</div>
				))
			}
		</div>
		<div id="dialogContainer" style={{display:dlg ? 'unset' : 'none'}}>

		</div>
	</div>)
}


const PlanTable = props => {
	const [showWay, setShowWay] = useState(false)
	let posPlan = props.plan.bilder.map(b => {return {title:b.title, point:b.point, pos:((b.followers && props.part==1) ? b.followers : b.leaders)[props.pos]}})
	return (<div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
		<div>Position:
		<Radio name="pos" checked={props.pos==-1} set={e => props.setPos(-1)} txt="-"/>
		{
			[...Array(props.plan.pairs).keys()].map(i => <Radio name="pos" checked={props.pos==i} set={e => props.setPos(i)} txt={i+1} key={i}/>)
		}
		</div>
		{props.pos >= 0 && <div>
			<Radio name="part" checked={props.part==1} set={e=>props.setPart(1)} txt="Dame"/>
			<Radio name="part" checked={props.part==2} set={e=>props.setPart(2)} txt="Herr"/>
			<MyInput type="checkbox" checked={showWay} set={setShowWay}/>Strecke anzeigen
		</div>}
		<div>Klicke auf eine Zeile, um das entsprechende Bild zu öffnen:</div>
		<table id="plan" style={{borderCollapse:'collapse', border: '1px solid black', textAlign:'left'}}>
			<colgroup>
				<col style={{border: '1px solid black'}}></col>
				{props.pos >= 0 && <col></col> }
				{props.pos >= 0 && <col style={{borderRight: '1px solid black'}}></col> }
				{showWay==1 && props.pos >= 0 && <col></col> }
				{showWay==1 && props.pos >= 0 && <col></col> }
				{showWay==1 && props.pos >= 0 && <col style={{borderRight: '1px solid black'}}></col> }
				<col></col>
			</colgroup>
			<tbody>
				<tr style={{position:'sticky', top:'0', zIndex:'2', height:'25px', backgroundColor:'white', borderTop:'1px solid black'}}>
					<th rowSpan={props.pos < 0 ? 1 : 2}>Bild</th>
					{props.pos >= 0 && <th colSpan="2">Position</th>}
					{showWay==1 && props.pos >= 0 && <th colSpan="3">Strecke</th>}
					<th rowSpan={props.pos < 0 ? 1 : 2}>Punkt ist</th>
				</tr>
				{props.pos >= 0 && <tr style={{position:'sticky', top:'25px', zIndex:'2', height:'25px', backgroundColor:'white', borderBottom:'1px solid black'}}>
					<th>X</th>
					<th>Y</th>
					{showWay==1 && <th>X</th>}
					{showWay==1 && <th>Y</th>}
					{showWay==1 && <th>m</th>}
				</tr>}
				{
					posPlan.map((bild,idx) => {
						let dx = (props.pos >= 0 && idx > 0) ? Math.abs(bild.pos[0] - posPlan[idx-1].pos[0]) : ""
						let dy = (props.pos >= 0 && idx > 0) ? Math.abs(bild.pos[1] - posPlan[idx-1].pos[1]) : ""
						return (<tr onClick={e => {props.select(idx)}} style={{backgroundColor:idx%2==0 ? '#aaa' : '#ddd', border: props.selected == idx && '3px solid cyan'}} key={idx}>
							<td>{idx+1}: {bild.title}</td>
							{props.pos >= 0 && <td>{bild.pos[0]}</td>}
							{props.pos >= 0 && <td>{bild.pos[1]}</td>}
							{showWay==1 && props.pos >= 0 && <td>{dx}</td>}
							{showWay==1 && props.pos >= 0 && <td>{dy}</td>}
							{showWay==1 && props.pos >= 0 && <td>{idx > 0 && Math.round(Math.sqrt(dx*dx+dy*dy)*10)/10}</td>}
							<td>{bild.point}</td>
						</tr>)
					})
				}
		</tbody></table>
	</div>)
}

const BildTab = props => {
	const [animated, setAnimated] = useState(false)
	let both = props.bild.followers != undefined
	let bothPrev = props.bild2.followers != undefined
	let bilder = props.plan.bilder
	let bild = bilder[props.selectedBild]
	return (<div>
		<div className="header">
			{props.selectedBild > 0 ? <div className="arrowLink left">
				<div style={{backgroundImage: 'url(img/arrow3.png)'}} onClick={e => props.selectBild(props.selectedBild-1)}></div>
				<div onClick={e => props.selectBild(props.selectedBild-1)}>Bild {props.selectedBild}: {bilder[props.selectedBild-1].title}</div>
			</div> : <div></div>}
			<h3>Bild {props.selectedBild+1}: {bild.title} <button onClick={() => props.setEditMode(true)}>✎</button></h3>
			{props.selectedBild < bilder.length-1 && <div className="arrowLink right">
				<div onClick={e => props.selectBild(props.selectedBild+1)}>Bild {props.selectedBild+2}: {bilder[props.selectedBild+1].title}</div>
				<div style={{backgroundImage: 'url(img/arrow3.png)'}} onClick={e => props.selectBild(props.selectedBild+1)}></div>
			</div>}
		</div>
		<Bild
			{...props}
			bild={bild}
			animated={animated}
			showSelected={(props.part==2 || !(both || (bothPrev && animated)))}
			style={{display:'inline-block'}}
		/>
		<div style={{display:'inline-flex', verticalAlign:'top', margin:'0px 20px', flexDirection:'column', alignItems:'center'}}>
			<div>
				<button style={{width:'75px'}} onClick={() => setAnimated(!animated)}>{animated ? 'stop' : 'animation'}</button>
			</div>
			<div style={{margin:'0px 20px'}}>Punkt ist: {props.bild.point}</div>
			<BildTable {...props} both={both}/>
		</div>
	</div>)
}

const Bild = props => {
	let both = props.bild.followers != undefined
	let bothPrev = props.bild2.followers != undefined

	const selectX = x => {
		let selected = []
		let unselected = []
		props.bild.leaders.forEach((p,i) => {
			if (p[0] != x) return
			if(props.selectedPoints.includes(i)) selected.push(i)
			else unselected.push(i)
		})
		if(props.bild.followers) {
			props.bild.followers.forEach((p,i) => {
				if (p[0] != x) return
				if(props.selectedPoints.includes(i+8)) selected.push(i+8)
				else unselected.push(i+8)
			})
		}
		if(unselected.length == 0) {
			props.setSelectedPoints(props.selectedPoints.filter(i => !selected.includes(i)))
		} else {
			props.setSelectedPoints([...props.selectedPoints, ...unselected])
		}
	}

	const selectY = y => {
		let selected = []
		let unselected = []
		props.bild.leaders.forEach((p,i) => {
			if (p[1] != y) return
			if(props.selectedPoints.includes(i)) selected.push(i)
			else unselected.push(i)
		})
		if(props.bild.followers) {
			props.bild.followers.forEach((p,i) => {
				if (p[1] != y) return
				if(props.selectedPoints.includes(i+8)) selected.push(i+8)
				else unselected.push(i+8)
			})
		}
		if(unselected.length == 0) {
			props.setSelectedPoints(props.selectedPoints.filter(i => !selected.includes(i)))
		} else {
			props.setSelectedPoints([...props.selectedPoints, ...unselected])
		}
	}

	let xVals = new Set()
	let yVals = new Set()
	props.bild.leaders.forEach((p,i) => {
		xVals.add(p[0])
		yVals.add(p[1])
	})
	if(props.bild.followers) {
		props.bild.followers.forEach((p,i) => {
			xVals.add(p[0])
			yVals.add(p[1])
		})
	}

	const toggle = i => {
		let s = [...props.selectedPoints]
		if(s.includes(i)) s = s.filter(x => x!= i)
		else s.push(i)
		props.setSelectedPoints(s)
	}

	const Point = (props) => {
		let from = {
			x:(props.from[0] * 5 + 40)*props.unit,
			y:(props.from[1] * 5 + 40)*props.unit
		}
		let to = {
			x: (props.to[0] * 5 + 40)*props.unit,
			y: (props.to[1] * 5 + 40)*props.unit
		}

		const [springs, api] = useSpring(() => ({to}))
		const start = () => {
			api.start({from:from, to:from, config:{duration:100}})

			setTimeout(() => api.start({from, to, config:{duration:3000}}), 500)
			setTimeout(() => start(), 4500)
		}
		useEffect(() => {
			if(props.animated) {
				start()
			}
		}, [])
		let style = {
			position:'absolute',
			left:'-13px',
			top:'-13px',
			width: '20px',
			height: '20px',
			borderRadius: '50%',
			border: `2px ${props.bstyle} ${props.selected ? 'red' : 'black'}`,
			color: props.selected ? 'red' : 'black',
			backgroundColor: props.marked ? 'cyan' : 'white',
			padding: '0px 1px 2px 1px',
			...springs
		}
		return (props.show || props.animated) && <animated.div className="clickable" onClick={props.onClick} style={style} >{props.pos}</animated.div>
	}

	let followers = (props.bild.followers ?? props.bild.leaders)
	let followers2 = (props.bild2.followers ?? props.bild2.leaders)
	return <div style={{
			width: 80*props.unit + 'px',
			height: 80*props.unit + 'px',
			marginTop: '30px',
			position: 'relative',
			...props.style
		}}>
		<table className="grid"><tbody>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
		</tbody></table>
		{props.bild.leaders.map((p,j) => {
			let from = props.bild2.leaders[j]
			return <Point
				key={j}
				from={from}
				to={p}
				pos={j+1}
				selected={props.pos == j && props.showSelected}
				marked={props.selectedPoints.includes(j)}
				onClick={() => toggle(j)}
				animated={props.animated}
				show={true}
				unit={props.unit}
				bstyle="solid"
				/>
		})}
		{(both || bothPrev) && followers.map((p,j) => {
			let from = followers2[j]
			return <Point
				key={j}
				from={from}
				to={p}
				pos={j+1}
				selected={props.pos == j && props.part==1}
				marked={props.selectedPoints.includes(j+8)}
				onClick={() => toggle(j+8)}
				animated={props.animated}
				show={both}
				unit={props.unit}
				bstyle="dashed"
				/>
		})}
		{
			Array.from(xVals).map((v,idx) => <Marker onClick={() => selectX(v)} unit={props.unit} key={idx} val={v} x={true}/>)
		}
		{
			Array.from(yVals).map((v,idx) => <Marker onClick={() => selectY(v)} unit={props.unit} key={idx} val={v} x={false}/>)
		}
	</div>
}

const BildTable = props => {
	let both = props.both
	let csl = props.selectedPoints.filter(x => x < 8).length
	let csf = props.selectedPoints.length-csl
	let pairs = props.bild.leaders.length
	const select = arr => {
		let s = [...props.selectedPoints]
		arr.forEach(i => {
			if(i%8 < pairs && !s.includes(i)) s.push(i)
		})
		props.setSelectedPoints(s)
	}

	const unselect = arr => {
		let s = props.selectedPoints.filter(i => !arr.includes(i))
		props.setSelectedPoints(s)
	}

	const edit = () => {
		props.showDlg(<div><EditTable {...props}/><button style={{margin:'10px'}} onClick={props.closeDlg}>ok</button></div>)
	}

	return (<table style={{ borderCollapse:'collapse', border:'1px solid black', verticalAlign:'top'}}>
		<colgroup>
			<col style={{borderRight: '1px solid black'}}></col>
			<col></col>
			<col style={{borderRight: '1px solid black'}}></col>
			<col></col>
			{both && <col></col>}
			{both && <col></col>}
		</colgroup>
		<tbody>
			<tr>
				<th>{props.editMode && <button onClick={edit}>✎</button>}</th>
				<th colSpan="2"
					className="clickable"
					style={{backgroundColor: csl == pairs ? 'cyan' : 'white'}}
					onClick={() => csl == pairs ? unselect(utils.range(0,8)) : select(utils.range(0,8))}
					>{both ? 'Herr' : 'Paar'}</th>
				{both && <th colSpan="2"
					className="clickable"
					style={{backgroundColor: csf == pairs ? 'cyan' : 'white'}}
					onClick={() => csf == pairs ? unselect(utils.range(8,16)) : select(utils.range(8,16))}
					>Dame</th>}
			</tr>
			<tr>
				<th>Pos</th>
				<th>X</th>
				<th>Y</th>
				{both && <th>X</th>}
				{both && <th>Y</th>}
			</tr>
			{props.bild.leaders.map((p,j) => {
				let selL = props.selectedPoints.includes(j)
				let styleL = {
					color: (props.pos == j && (!both || props.part==2)) ? 'red' : 'black',
					backgroundColor: selL ? 'cyan' : 'white'
				}
				let onClickL = () => selL ? unselect([j]) : select([j])
				let selF = props.selectedPoints.includes(j+8)
				let styleF = {
					color: (props.pos == j && (!both || props.part==1)) ? 'red' : 'black',
					backgroundColor: selF ? 'cyan' : 'white'
				}
				let onClickF = () => selF ? unselect([j+8]) : select([j+8])
				return (<tr key={j}>
					<td>{j+1}</td>
					<td className="clickable" style={styleL} onClick={onClickL}>{p[0]}</td>
					<td className="clickable" style={styleL} onClick={onClickL}>{p[1]}</td>
					{both && <td className="clickable" style={styleF} onClick={onClickF}>{props.bild.followers[j][0]}</td>}
					{both && <td className="clickable" style={styleF} onClick={onClickF}>{props.bild.followers[j][1]}</td>}
				</tr>)})}
		</tbody>
	</table>)
}

const EditTab = props => {
	let bilder = props.plan.bilder

	let both = props.bild.followers != undefined
	const [animated, setAnimated] = useState(false)
	let bothPrev = props.bild2.followers != undefined
	let xValsSelected = new Set()
	let yValsSelected = new Set()
	props.bild.leaders.forEach((p,i) => {
		if(props.selectedPoints.includes(i)) {
			xValsSelected.add(p[0])
			yValsSelected.add(p[1])
		}
	})
	if(props.bild.followers) {
		props.bild.followers.forEach((p,i) => {
			if(props.selectedPoints.includes(i+8)) {
				xValsSelected.add(p[0])
				yValsSelected.add(p[1])
			}
		})
	}
	xValsSelected = Array.from(xValsSelected).toSorted((x,y) => x-y)
	yValsSelected = Array.from(yValsSelected).toSorted((x,y) => x-y)
	let distX = 0
	let distY = 0
	if(xValsSelected.length > 2) {
		distX = xValsSelected[1]-xValsSelected[0]
		for(let i = 2; i < xValsSelected.length; i++) {
			if(xValsSelected[i]-xValsSelected[i-1] == distX) continue
			distX=0
			break
		}
	}
	if(yValsSelected.length > 2) {
		distY = yValsSelected[1]-yValsSelected[0]
		for(let i = 2; i < yValsSelected.length; i++) {
			if(yValsSelected[i]-yValsSelected[i-1] == distY) continue
			distY=0
			break
		}
	}

	const save = () => {
		let plan = utils.clone(props.plan)
		plan.bilder[props.selectedBild] = props.bild
		postPHP("uploadFormationPlan",{team:0, json:JSON.stringify(plan.bilder), pairs:plan.pairs, origin:props.origin}, res=>{
			plan.id=res
			props.updatePlan(plan)
		}, err=>{console.log("ERR", err)});
	}

	const setBild = b => {
		let plan = utils.clone(props.plan)
		plan.bilder[props.selectedBild] = b
		props.setPlan(plan)
	}

	const setDistX = (val, fix) => {
		let bild = utils.clone(props.bild)
		props.selectedPoints.forEach( i => {
			if(i < 8) {
				let p = bild.leaders[i]
				p[0] = fix + val*(p[0]-fix)/distX
			} else if(bild.followers) {
				let p = bild.followers[i-8]
				p[0] = fix + val*(p[0]-fix)/distX
			}
		})
		setBild(bild)
		props.closeDlg()
	}
	const setDistY = (val, fix) => {
		let bild = utils.clone(props.bild)
		props.selectedPoints.forEach( i => {
			if(i < 8) {
				let p = bild.leaders[i]
				p[1] = fix + val*(p[1]-fix)/distY
			} else if(bild.followers) {
				let p = bild.followers[i-8]
				p[1] = fix + val*(p[1]-fix)/distY
			}
		})
		setBild(bild)
		props.closeDlg()
	}

	const swap = () => {
		let p1 = getPoint(props.bild, props.selectedPoints[0])
		let p2 = getPoint(props.bild, props.selectedPoints[1])
		let bild = utils.clone(props.bild)
		setPoint(bild, props.selectedPoints[0], p2)
		setPoint(bild, props.selectedPoints[1], p1)
		setBild(bild)
	}

	const seperate = () => {
		let bild = utils.clone(props.bild)
		bild.followers = utils.clone(bild.leaders)
		setBild(bild)
	}

	const unite = i => {
		let bild = utils.clone(props.bild)
		if(i == 1)
			bild.leaders = bild.followers
		delete bild.followers
		setBild(bild)
		props.closeDlg()
	}

	const uniteDlg = () => {
		let same = true
		for(let i in props.bild.leaders) {
			let p = props.bild.leaders[i]
			let p2 = props.bild.followers[i]
			if(p[0] != p2[0] || p[1] != p2[1]) {
				same = false
				break
			}
		}
		if(same) {
			unite(0)
			return
		}
		props.showDlg((<div style={{textAlign:'center'}}>
			Lösche Punkte für<br/>
			<button style={{margin:'10px'}} onClick={() => unite(1)}>Herren</button>
			<button style={{margin:'10px'}} onClick={() => unite(0)}>Damen</button>
			<button style={{margin:'10px'}} onClick={props.closeDlg}>abbrechen</button>
			<br/>
			</div>))
	}

	const newBild = idx => {
		let plan = utils.clone(props.plan)
		let bild = utils.clone(props.bild)
		plan.bilder.splice(idx,0,bild)
		props.setPlan(plan)
		props.selectBild(idx)
	}

	const deleteBild = () => {
		props.showDlg(<ConfirmDlg title="Dieses Bild löschen?" closeDlg={props.closeDlg} func={() => {
			let plan = utils.clone(props.plan)
			plan.bilder.splice(props.selectedBild,1)
			props.setPlan(plan)
			if(props.selectedBild >= plan.bilder.length) props.selectBild(plan.bilder.length-1)}}/>)
	}

	return (<div>
		<div className="header">
			{props.selectedBild > 0 ? <div className="arrowLink left">
				<div style={{backgroundImage: 'url(img/arrow3.png)'}} onClick={e => props.selectBild(props.selectedBild-1)}></div>
				<div onClick={e => props.selectBild(props.selectedBild-1)}>Bild {props.selectedBild}: {bilder[props.selectedBild-1].title}</div>
			</div> : <div></div>}
			<button onClick={()=>newBild(props.selectedBild)}>+</button>
			<h3>
				Bild {props.selectedBild+1}: <MyInput style={{maxWidth:'25vw'}} value={props.bild.title} set={s => setBild({...props.bild, title:s})}/>
				<button onClick={() => props.setEditMode(false)}>✕</button><br/>
				<button onClick={deleteBild} style={{color:'red'}}>Bild löschen</button>
			</h3>
			<button onClick={()=>newBild(props.selectedBild+1)}>+</button>
			{props.selectedBild < bilder.length-1 && <div className="arrowLink right">
				<div onClick={e => props.selectBild(props.selectedBild+1)}>Bild {props.selectedBild+2}: {bilder[props.selectedBild+1].title}</div>
				<div style={{backgroundImage: 'url(img/arrow3.png)'}} onClick={e => props.selectBild(props.selectedBild+1)}></div>
			</div>}
		</div>
		<button style={{width:'75px'}} onClick={() => save()}>upload</button><br/>
		<Bild
			{...props}
			animated={animated}
			showSelected={(props.part==2 || !(both || (bothPrev && animated)))}
			style={{display:'inline-block'}}
		/>
		<div style={{display:'inline-block', width:props.rWidth, verticalAlign:'top'}}>
			<Controls {...props} setBild={setBild}/>
			<div style={{display:'inline-flex', verticalAlign:'top', margin:'0px 20px', flexDirection:'column', alignItems:'center'}}>
				{distX > 0 && <button onClick={() => props.showDlg(<DistDialog val={distX} title="Seitenabstände" values={xValsSelected} cancel={props.closeDlg} save={setDistX}/>)} style={{margin:'10px'}}>Seitenabstände...</button>}
				{distY > 0 && <button onClick={() => props.showDlg(<DistDialog val={distY} title="Tiefenabstände" values={yValsSelected} cancel={props.closeDlg} save={setDistY}/>)} style={{margin:'10px'}}>Tiefenabstände...</button>}
				{props.selectedPoints.length == 2 && <button onClick={swap} style={{margin:'10px'}}>tauschen</button>}
				{both
					? <button onClick={uniteDlg} style={{margin:'10px'}}>zusammenführen (Herr/Dame)</button>
					: <button onClick={seperate} style={{margin:'10px'}}>trennen (Herr/Dame)</button>
				}
				<BildTable {...props} both={both} setBild={setBild}/>
				<button style={{width:'75px', margin:'10px'}} onClick={() => setAnimated(!animated)}>{animated ? 'stop' : 'animation'}</button>
			</div>
			<div style={{display:'inline-block', verticalAlign:'top', margin:'0px 20px'}}>Punkt ist: <MyInput style={{maxWidth:'50vw'}} value={props.bild.point} set={s => setBild({...props.bild, point:s})}/></div>
			<div style={{display:'inline-flex', verticalAlign:'top', margin:'0px 20px', flexDirection:'column', alignItems:'center'}}>
			</div>
		</div>
	</div>)

}

const EditTable = props => {
	const [bild, setBild] = useState(utils.clone(props.bild))
	let both = props.both
	let pairs = props.bild.leaders.length

	const setVal = (i,j,v) => {
		let b = utils.clone(bild)
		if(i < 8) b.leaders[i][j] = v
		else b.followers[i-8][j] = v
		props.setBild(b)
		setBild(b)
	}

	return (<table style={{ borderCollapse:'collapse', border:'1px solid black', verticalAlign:'top'}}>
		<colgroup>
			<col style={{borderRight: '1px solid black'}}></col>
			<col></col>
			<col style={{borderRight: '1px solid black'}}></col>
			<col></col>
			{both && <col></col>}
			{both && <col></col>}
		</colgroup>
		<tbody>
			<tr>
				<th rowSpan="2">Pos</th>
				<th colSpan="2">{both ? 'Herr' : 'Paar'}</th>
				{both && <th colSpan="2">Dame</th>}
			</tr>
			<tr>
				<th>X</th>
				<th>Y</th>
				{both && <th>X</th>}
				{both && <th>Y</th>}
			</tr>
			{bild.leaders.map((p,j) => {
				return (<tr key={j}>
					<td>{j+1}</td>
					<td><MyInput style={{width:'30px'}} set={v => setVal(j,0,v)} value={p[0]}/></td>
					<td><MyInput style={{width:'30px'}} set={v => setVal(j,1,v)} value={p[1]}/></td>
					{both && <td><MyInput style={{width:'30px'}} set={v => setVal(j+8,0,v)} value={bild.followers[j][0]}/></td>}
					{both && <td><MyInput style={{width:'30px'}} set={v => setVal(j+8,1,v)} value={bild.followers[j][1]}/></td>}
				</tr>)})}
		</tbody>
	</table>)
}

const Controls = props => {
	const min = 40
	const s = [2*min,Math.sqrt(2)*min,min]
	const s2 = s.map(x => Math.sqrt(2)*x/2)
	const w = 2*s[0]+2*s[1]
	const move = (x,y) => {
		let bild = utils.clone(props.bild)
		props.selectedPoints.forEach(i => {
			if(i < 8)
				bild.leaders[i] = [bild.leaders[i][0]+x, bild.leaders[i][1]+y]
			else if(bild.followers)
				bild.followers[i-8] = [bild.followers[i-8][0]+x, bild.followers[i-8][1]+y]
		})
		props.setBild(bild)
	}
	return (<div style={{width:w+'px', height:w+'px', position:'relative', display:'inline-block', verticalAlign:'top'}}>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:'0px',
				width:s[0] + 'px',
				height:s[0]/2 + 'px',
				fontSize: '22px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0,-1)}>1</div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:s[0]/2 + 'px',
				width:s[1] + 'px',
				height:s[1]/2 + 'px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0,-0.5)}>0.5</div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:(s[0]+s[1])/2 + 'px',
				width:s[2] + 'px',
				height:s[2]/2 + 'px',
				fontSize:'8px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0,-0.25)}>0.25</div>

			<div className="arrow" style={{
				left:w/2 + 'px',
				top:'0px',
				width:s[0] + 'px',
				height:s[0]/2 + 'px',
				transformOrigin: `${s[0]/2}px ${w/2}px`,
				transform: 'translate(-50%) rotate(45deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(1,-1)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:s[0]/2 + 'px',
				width:s[1] + 'px',
				height:s[1]/2 + 'px',
				transformOrigin: `${s[1]/2}px ${w/2-s[0]/2}px`,
				transform: 'translate(-50%) rotate(45deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0.5,-0.5)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:(s[0]+s[1])/2 + 'px',
				width:s[2] + 'px',
				height:s[2]/2 + 'px',
				transformOrigin: `${s[2]/2}px ${w/2-s[0]/2-s[1]/2}px`,
				transform: 'translate(-50%) rotate(45deg)',
				fontSize:'8px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0.25,-0.25)}></div>

			<div className="arrow" style={{
				left:w/2 + 'px',
				top:'0px',
				width:s[0] + 'px',
				height:s[0]/2 + 'px',
				transformOrigin: `${s[0]/2}px ${w/2}px`,
				transform: 'translate(-50%) rotate(90deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(1,0)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:s[0]/2 + 'px',
				width:s[1] + 'px',
				height:s[1]/2 + 'px',
				transformOrigin: `${s[1]/2}px ${w/2-s[0]/2}px`,
				transform: 'translate(-50%) rotate(90deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0.5,0)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:(s[0]+s[1])/2 + 'px',
				width:s[2] + 'px',
				height:s[2]/2 + 'px',
				transformOrigin: `${s[2]/2}px ${w/2-s[0]/2-s[1]/2}px`,
				transform: 'translate(-50%) rotate(90deg)',
				fontSize:'8px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0.25,0)}></div>

			<div className="arrow" style={{
				left:w/2 + 'px',
				top:'0px',
				width:s[0] + 'px',
				height:s[0]/2 + 'px',
				transformOrigin: `${s[0]/2}px ${w/2}px`,
				transform: 'translate(-50%) rotate(135deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(1,1)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:s[0]/2 + 'px',
				width:s[1] + 'px',
				height:s[1]/2 + 'px',
				transformOrigin: `${s[1]/2}px ${w/2-s[0]/2}px`,
				transform: 'translate(-50%) rotate(135deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0.5,0.5)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:(s[0]+s[1])/2 + 'px',
				width:s[2] + 'px',
				height:s[2]/2 + 'px',
				transformOrigin: `${s[2]/2}px ${w/2-s[0]/2-s[1]/2}px`,
				transform: 'translate(-50%) rotate(135deg)',
				fontSize:'8px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0.25,0.25)}></div>

			<div className="arrow" style={{
				left:w/2 + 'px',
				top:'0px',
				width:s[0] + 'px',
				height:s[0]/2 + 'px',
				transformOrigin: `${s[0]/2}px ${w/2}px`,
				transform: 'translate(-50%) rotate(180deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0,1)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:s[0]/2 + 'px',
				width:s[1] + 'px',
				height:s[1]/2 + 'px',
				transformOrigin: `${s[1]/2}px ${w/2-s[0]/2}px`,
				transform: 'translate(-50%) rotate(180deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0,0.5)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:(s[0]+s[1])/2 + 'px',
				width:s[2] + 'px',
				height:s[2]/2 + 'px',
				transformOrigin: `${s[2]/2}px ${w/2-s[0]/2-s[1]/2}px`,
				transform: 'translate(-50%) rotate(180deg)',
				fontSize:'8px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(0,0.25)}></div>

			<div className="arrow" style={{
				left:w/2 + 'px',
				top:'0px',
				width:s[0] + 'px',
				height:s[0]/2 + 'px',
				transformOrigin: `${s[0]/2}px ${w/2}px`,
				transform: 'translate(-50%) rotate(225deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-1,1)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:s[0]/2 + 'px',
				width:s[1] + 'px',
				height:s[1]/2 + 'px',
				transformOrigin: `${s[1]/2}px ${w/2-s[0]/2}px`,
				transform: 'translate(-50%) rotate(225deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-0.5,0.5)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:(s[0]+s[1])/2 + 'px',
				width:s[2] + 'px',
				height:s[2]/2 + 'px',
				transformOrigin: `${s[2]/2}px ${w/2-s[0]/2-s[1]/2}px`,
				transform: 'translate(-50%) rotate(225deg)',
				fontSize:'8px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-0.25,0.25)}></div>

			<div className="arrow" style={{
				left:w/2 + 'px',
				top:'0px',
				width:s[0] + 'px',
				height:s[0]/2 + 'px',
				transformOrigin: `${s[0]/2}px ${w/2}px`,
				transform: 'translate(-50%) rotate(270deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-1,0)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:s[0]/2 + 'px',
				width:s[1] + 'px',
				height:s[1]/2 + 'px',
				transformOrigin: `${s[1]/2}px ${w/2-s[0]/2}px`,
				transform: 'translate(-50%) rotate(270deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-0.5,0)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:(s[0]+s[1])/2 + 'px',
				width:s[2] + 'px',
				height:s[2]/2 + 'px',
				transformOrigin: `${s[2]/2}px ${w/2-s[0]/2-s[1]/2}px`,
				transform: 'translate(-50%) rotate(270deg)',
				fontSize:'8px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-0.25,0)}></div>

			<div className="arrow" style={{
				left:w/2 + 'px',
				top:'0px',
				width:s[0] + 'px',
				height:s[0]/2 + 'px',
				transformOrigin: `${s[0]/2}px ${w/2}px`,
				transform: 'translate(-50%) rotate(315deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-1,-1)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:s[0]/2 + 'px',
				width:s[1] + 'px',
				height:s[1]/2 + 'px',
				transformOrigin: `${s[1]/2}px ${w/2-s[0]/2}px`,
				transform: 'translate(-50%) rotate(315deg)',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-0.5,-0.5)}></div>
			<div className="arrow" style={{
				left:w/2 + 'px',
				top:(s[0]+s[1])/2 + 'px',
				width:s[2] + 'px',
				height:s[2]/2 + 'px',
				transformOrigin: `${s[2]/2}px ${w/2-s[0]/2-s[1]/2}px`,
				transform: 'translate(-50%) rotate(315deg)',
				fontSize:'8px',
				backgroundImage:`url(img/arrow.png)`
			}} onClick={() => move(-0.25,-0.25)}></div>
		</div>)
}

const DistDialog = props => {
	const [val,setVal] = useState(props.val)
	const [fix, setFix] = useState(0)
	const inc = step => {
		let n = parseFloat(String(val).replace(",","."))
		if(n == NaN) n = props.val
		setVal(n += step)
	}

	return (<div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
			<div>{props.title}</div>
			<br/>
			<div style={{display:'flex'}} id="distControls">
				<button onClick={()=>inc(-0.5)} disabled={val <= 0.5}>-0.5</button>
				<button onClick={()=>inc(-1)} disabled={val <= 1}>-1</button>
				<MyInput value={val} set={setVal} style={{width:'50px', textAlign:'center'}}/>
				<button onClick={()=>inc(1)} >+1</button>
				<button onClick={()=>inc(0.5)} >+0.5</button>
			</div>
			<br/>
			<div>
				fixiere&nbsp;
				<MySelect value={fix} set={setFix}>
					{props.values.map((v,i) => <option key={i} value={i}>{v}</option>)}

				</MySelect>
			</div>
			<br/>
			<div>
				<button onClick={() => props.save(parseFloat(val),props.values[fix])} disabled={parseFloat(val) == NaN}>ok</button>
				&nbsp;&nbsp;&nbsp;
				<button onClick={props.cancel}>abbrechen</button>
			</div>
		</div>)
}

const InfoTab = props => {
	return (<div style={{textAlign:'left', display:'inline-block'}}>
			<h1>Über diese Seite</h1>
			<h2>▤ Die Übersichtstabelle</h2>
			In der Tabelle auf der ersten Seite sieht man eine Übersicht über alle Bilder.<br/>
			Man kann auch eine Position, sowie Herr oder Dame auswählen um zu jedem Bild die Position zu sehen.<br/>
			Zusätzlich kann man sich noch zu jedem Bild die Strecke aus dem vorherigen Bild anzeigen lassen(jeweils die Seitenmeter(X), Tiefenmeter(Y) und die Gesamtstrecke)<br/>
			Die Tabelle dient außerdem als Inhaltsverzeichnis. Mit klick auf eine Zeile gelangt man direkt zu dem entsprechenden Bild.
			<h2>▦ Einzelne Bilder</h2>
			Wenn ein einzelnes Bild geöffnet ist, sieht man alle Positionen auf graphisch dargestellt(unten ist "vorne") und in der Tabelle.<br/>
			Falls für Herren und Damen eigene Punkte definiert sind, werden Damen mit gestricheltem Rahmen dargestellt.<br/>
			Sofern auf der Übersichtseite eine Position ausgewählt wurde, dir der entsprechende Punkt in Orange dargestellt<br/>
			Durch Klick auf den button 'animation' kann die Bewegung vom letzten Bild in dieses abgespielt werden.<br/>
			Man Punkte hier auch auswählen. Dies dient hauptsächlich der bearbeitung(s.u.), kann aber auch hilfreich sein um schnell Einträge aus der Tabelle den Punkten im Bild oder umgekehrt hervorzuheben<br/>
			Auswahlmöglichkeiten:
			<ul>
				<li>Einzeln durch Klick auf den Punkt oder die Position in der Tabelle</li>
				<li>Spaltenweise in der Tabelle(alle Herren/Damen/Paare) durch Klick auf die Spaltenüberschrift</li>
				<li>Alle Punkte mit einem bestimmten Seiten- oder Tiefenmeter durch Klick auf den entsprechenden Meter am Rand des Plans</li>
			</ul>
			<h2>Bearbeiten</h2>
			Der Plan kann hier auch bearbeitet werden. Alle Änderungen werden zunächst automatisch auf dem Gerät gespeichert.<br/>
			Durch Klick auf den Button 'speichern' wird der gesamte Plan so wie gerade ist hochgeladen.<br/>
			Bei jedem Seitenaufbau wird der zuletzt hochgeladene Plan heruntergeladen, es sei denn man hat noch lokale Änderungen (In diesem Fall muss ggf. ein Admin die Änderungen zusammenfügen)<br/>
			Um lokale Änderungen zu verwerfen, kann man unten auf dieser Seite auf den Buton klicken. Anschließend kann man die Seite neu laden um die aktuellste Version herunterzuladen.<br/>
			Um den Bearbeitungsmodus zu aktivieren, kann man bei einem geöffneten Bild oben in der Titelleiste auf den Button ✎ klicken.
			Bearbeitungsmöglichkeiten (siehe auch Auswahlmöglichkeiten oben):
			<ul>
				<li>mit den schwarzen Pfeilen können alle ausgewählten Punkte in 1/4, 1/2 oder 1m-Schritten verschoben werden</li>
				<li>Wenn exakt 2 Punkte ausgewählt sind erscheint ein button um diese zu vertauschen</li>
				<li>Wenn Punkte mit mehr als 2 unterschiedlichen Seitenmetern ausgewählt sind und diese einen gleichmäßigen Abstand haben, erscheint ein Button um diesen zu bearbeiten, also die Punkte auseinander- oder zusammenziehen.</li>
				<li>Das gleiche gilt natürlich für die Tiefenmeter</li>
				<li>Wenn die Punkte pro Paar angegeben sind, kann man per klick auf den Button 'trennen (Herr/Dame)' die Punkte für Herren und Damen einzeln angeben. Ansonsten gibt es einen button um die Punkte zusammenzuführen(hier muss man noch auswählen ob man die Punkte der Herren oder Damen übernehmen möchte)</li>
				<li>natürlich kann man die Meter auch direkt eingeben indem man auf ✎ in der Tabelle klickt</li>
				<li>Um ein neues Bild vor oder nach dem angezeigten einzufügen, kann man auf den Button + vor oder nach dem Titel oben klicken. Hierbei wird das aktuelle Bild kopiert. Das neu erstellte Bild wird direkt geöffnet</li>
			</ul>
			<button style={{color:'red'}} onClick={() => {
				props.showDlg(<ConfirmDlg title="Alle lokale Änderungen endgültig verwerfen?" closeDlg={props.closeDlg} func={props.reset}/>)
			}}>Änderungen verwerfen</button>
		</div>)
}

const ConfirmDlg = props => {
	return (<div>
			<h3>{props.title}</h3>
			<div>
				<button style={{margin:'5px'}} onClick={ () => {
					props.func()
					props.closeDlg()
				}}>bestätigen</button>
				<button style={{margin:'5px'}} onClick={props.closeDlg}>abbrechen</button>
			</div>
		</div>)
}


const Radio = props => {
	return <div className="radio" style={{display:'inline-block', marginRight:'20px'}} onClick={props.set}><MyInput type="radio" name={props.name} checked={props.checked} set={props.set}/> {props.txt}</div>
}

const Marker = props => {
	let val = props.val
	let [x,y] = props.x ? [val,-8.25] :[-8.25,val]
	let left = (x*5+40)*props.unit-10
	let top = (y*5+40)*props.unit-13
	return <div className="clickable" onClick={props.onClick} style={{
		position:'absolute',
		left:left+'px',
		top:top+'px',
		width:'20px',
		height:'20px',
	}}>{val}</div>
}

const compare = (bilder1, bilder2, pairs) => {
	if(bilder1.length != bilder2.length) return false
	for(let i = 0; i < bilder1.length; i++) {
		let b1 = bilder1[i]
		let b2 = bilder2[i]
		if(b1.title != b2.title) return false
		if(b1.point != b2.point) return false
		if(b1.comment != b2.comment) return false
		for(let j = 0; j < pairs; j++) {
			if(b1.leaders[j][0] != b2.leaders[j][0]) return false
			if(b1.leaders[j][1] != b2.leaders[j][1]) return false
		}
		if(b1.followers) {
			if(!b2.followers) return false
			for(let j = 0; j < pairs; j++) {
				if(b1.followers[j][0] != b2.followers[j][0]) return false
				if(b1.followers[j][1] != b2.followers[j][1]) return false
			}
		} else if(b2.followers) return false
	}
	return true
}

const getPoint = (bild, i) => {
	return i < 8 ? bild.leaders[i] : bild.followers[i-8]
}
const setPoint = (bild, i, p) => {
	if(i < 8)	bild.leaders[i] = p
	else bild.followers[i-8] = p
}
