var urlForQuery = '/sql.php'

export const postPHP = (f, data, callback, errorCB=null) => {
	let formData = new FormData();
	for(let key in data) {
		formData.append(key, data[key])
	}
	var url = urlForQuery + '?f=' + f;
	fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: formData
  })
		.then(response => response.json())
		.then(json => {

			if(json.success) callback(json.data);
			else {
				if(errorCB == null) console.log(json.data)
				else errorCB(json.data);
			}
		});
}

export const queryPHP = (f, d, callback, errorCB=null) => {
	var data = {...d}
	var url = urlForQuery + '?f=' + f;
	for(var key in data) url += "&" + key + "=" + encodeURIComponent(data[key]);
	console.log(url)
	fetch(url)
		.then(response => response.json())
		.then(json => {

			if(json.success) callback(json.data);
			else {
				if(errorCB == null) console.log(json.data)
				else errorCB(json.data);
			}
		})
}


export const MyInput = props => {
	const p = {...props};
	const set = props.set;
	delete p.set;
	if(p.original !== undefined) {
		var classList = (p.className ?? "").split(" ");
		if(p.original != p.value) {
			classList.push("changed");
		}
		p.className = classList.join(" ");
	}
	return <input {...p} onChange={e => set(e.target.type == "checkbox" ? (e.target.checked ? 1 : 0) : e.target.value, props.idx)}/>
}

export const MySelect = props => {
	const p = {...props};
	const set = props.set;
	delete p.set;
	delete p.children;
	if(p.original !== undefined) {
		var classList = (p.className ?? "").split(" ");
		if(p.original != p.value) {
			classList.push("changed");
		}
		p.className = classList.join(" ");
	}
	return (<select {...p} onChange={e => {
		if(props.hook) props.hook(e.target.value, props.idx);
		set(e.target.value, props.idx)}
	}>{props.children}</select>)
}

export const utils = {
	clone: obj => {
		return structuredClone(obj)
		//return JSON.parse(JSON.stringify(obj))
	},
	range: (n,m) => Object.keys(Array.from(Array(m-n))).map(x => parseInt(x)+n),

}
