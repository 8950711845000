import {createStore} from 'redux'
import {createSlice, configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const initial = {
	plan: {
		id: -1,
		pairs: 8,
		saved: null,
		changed: false,
		bilder: []
	},
	planOriginal: {
		id: -1,
		pairs: 8,
		saved: null,
		changed: false,
		loaded:new Date(),
		bilder: []
	},
	settings: {
		pos:-1,
		part:1,
		allowCookies:false
	}
}

const slice = createSlice({
	name: 'storage',
	initialState: initial,

	reducers: {
		storePlan: (state, action) => {
			state.plan = action.payload;
		},
		storePlanOriginal: (state, action) => {
			state.planOriginal = action.payload;
		},
		storeSettings: (state, action) => {
			state.settings = action.payload;
		}
	}
});

export const getStoredPlan = state => state.plan;
export const getStoredPlanOriginal = state => state.planOriginal;
export const getStoredSettings = state => state.settings;
export const {storePlan, storePlanOriginal, storeSettings} = slice.actions;

const persistConfig = {
	key: 'root',
	storage
}
const persistedReducer = persistReducer(persistConfig, slice.reducer)

export const store = configureStore({
	reducer: persistedReducer
});

export const persistor = persistStore(store)
